/* You can add global styles to this file, and also import other style files */

// Import Tailwind CSS
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Style+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two&display=swap");
@import "./variables.scss";

@layer base {
  html {
    height: 100vh;
    font-family: "SF Pro Display", sans-serif;
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

body {
  height: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.intro-text {
  h1,
  h3 {
    font-family: $intro-font;
  }
}

/* Landing page */
.landing {
  width: 100vw;
  max-width: 100%;
  overflow-y: auto;
  display: flex;
}
.content {
  flex: 1;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  background: url("./assets/images/fixed.png");
  background-size: 100% auto;
  background-repeat: repeat;

  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 70%;

    > * {
      min-height: 2.25rem;
      margin-bottom: 1.25rem;
    }
  }

  .fixed-element {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 50vw;
    right: 50vw;
    z-index: -1;
  }

  .logo {
    width: 25vw;
    margin-bottom: 3.125rem;
  }
}
.promo-image {
  flex: 1;
  background: url("./assets/images/landing-page-photo.png");
  background-size: 100% auto;
  background-repeat: repeat;
}

/* Tooltip container */
.tooltiptext {
  visibility: hidden;
  width: 7.5rem;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -3.75rem;
}
/* Tooltip arrow */
.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
/* Show tooltip on hover */
.group:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Media queries */
@media (max-width: 447px) {
  .buttons-div {
    display: flex;
    justify-items: center;
    flex-direction: column;
    margin-top: 0;
    button {
      width: 100%;
      margin-top: 1rem;
    }
  }
  .product-details {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .content {
    background-size: 100% auto;
    background-repeat: repeat;

    .logo {
      width: 50%;
    }

    .fixed-element {
      right: 0;
      width: 100vw;
    }
  }
}
//menu classes
@media (max-width: 767px) {
  .promo-image {
    display: none;
  }
  .menu {
    &-close {
      transform: translateX(-100%);
    }
    &-open {
      transform: translateX(0);
      display: flex !important;
    }
    &-open,
    &-close {
      position: fixed;
      top: 60px;
      bottom: 0;
      transition: 0.5s;
      transform-origin: left;
    }
  }
}

@media (min-width: 767px) {
  .menu {
    &-open {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      left: 0;
    }
  }
}

@media (max-width: 600px) {
  .content {
    .logo {
      width: 60%;
    }

    .fixed-element {
      right: 0;
      width: 100vw;
    }
  }
}
